import {
  Avatar,
  Box,
  Hidden,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  RadioButtonChecked,
  RadioButtonUnchecked
} from '@mui/icons-material';
import { Image } from 'common/components/Image';
import { useCallback, useState } from 'react';
import dynamic, { LoaderComponent } from 'next/dynamic';
import { AnimatePresence } from 'framer-motion';
import { MotionBoxProps } from 'common/components/MotionBox';
import { useOnScreen } from 'common/hooks/useOnScreen';

const MotionBox = dynamic(
  () =>
    import('common/components/MotionBox').then(
      (mod) => mod.MotionBox
    ) as LoaderComponent<MotionBoxProps>
);

export interface QuoteCarouselProps {
  backgroundImage: string;
  items: QuoteCarouselItemProps[];
}

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};
const QuoteCarousel = (props: QuoteCarouselProps) => {
  const theme = useTheme();
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const isOnscreen = useOnScreen(ref, 300);
  const [[value, direction], setState] = useState([0, 0]);

  const handlePrev = useCallback(() => {
    if (value === 0) {
      setState([props.items.length - 1, -1]);
      return;
    }
    setState([value - 1, -1]);
  }, [value, props.items]);

  const handleNext = useCallback(() => {
    if (value === props.items.length - 1) {
      setState([0, 1]);
      return;
    }
    setState([value + 1, 1]);
  }, [value, props.items]);

  const createSetValue = useCallback(
    (newValue: number) => () => setState([newValue, value > newValue ? -1 : 1]),
    [value]
  );

  const onDragEnd = useCallback(
    (e, { offset, velocity }) => {
      const swipe = swipePower(offset.x, velocity.x);

      if (swipe < -swipeConfidenceThreshold) {
        handleNext();
      } else if (swipe > swipeConfidenceThreshold) {
        handlePrev();
      }
    },
    [value, props.items]
  );

  return (
    <Box
      ref={setRef}
      sx={{
        ...(isOnscreen
          ? theme.mixins.makeBackground(props.backgroundImage)
          : {}),
        minHeight: '47vw',
        backgroundSize: '100vw',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        backgroundPosition: 'center',
        '&:after': {
          display: 'none'
        },
        [theme.breakpoints.down('md')]: {
          minHeight: theme.spacing(80),
          backgroundSize: 'cover'
        }
      }}
      paddingX={2}
    >
      <Box
        display={'flex'}
        flex={1}
        flexDirection={'row'}
        justifyContent={'center'}
        width={'100%'}
        alignItems={'center'}
      >
        <Hidden>
          {props.items.length > 1 && (
            <IconButton
              aria-label={'Quote Carousel Control Previous'}
              onClick={handlePrev}
              size='large'
            >
              <ChevronLeft fontSize={'large'} />
            </IconButton>
          )}
        </Hidden>
        <Box
          width={'100%'}
          maxWidth={1200}
          position={'relative'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <AnimatePresence initial={false} custom={direction}>
            <MotionBox
              position={'absolute'}
              maxWidth={'100vw'}
              key={value}
              custom={direction}
              variants={variants}
              initial='enter'
              animate='center'
              exit='exit'
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
              }}
              drag='x'
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={onDragEnd}
            >
              <QuoteCarouselItem {...props.items[value]} />
            </MotionBox>
          </AnimatePresence>
        </Box>
        <Hidden mdDown={true}>
          {props.items.length > 1 && (
            <IconButton
              aria-label={'Quote Carousel Control Next'}
              onClick={handleNext}
              size='large'
            >
              <ChevronRight fontSize={'large'} />
            </IconButton>
          )}
        </Hidden>
      </Box>
      <Box display={'flex'} justifyContent={'center'} paddingBottom={2}>
        {props.items.length > 1 &&
          Array(props.items.length)
            .fill(null)
            .map((_, i) => {
              return (
                <IconButton
                  size={'small'}
                  key={i}
                  onClick={createSetValue(i)}
                  aria-label={`Quote Carousel Slide ${i + 1}`}
                >
                  {i === value ? (
                    <RadioButtonChecked />
                  ) : (
                    <RadioButtonUnchecked />
                  )}
                </IconButton>
              );
            })}
      </Box>
    </Box>
  );
};

QuoteCarousel.strapiComponent = 'maastr.quote-carousel';

export interface QuoteCarouselItemProps {
  image?: string;
  quote: string;
  name: string;
  tagline?: string;
}

const QuoteCarouselItem = ({
  quote,
  image,
  name,
  tagline
}: QuoteCarouselItemProps) => {
  const theme = useTheme();

  return (
    <Box
      display={'flex'}
      alignSelf={'center'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Box marginBottom={7}>
        <Hidden xlDown={true}>
          <Typography variant={'h1'}>“{quote}”</Typography>
        </Hidden>
        <Hidden lgUp={true}>
          <Typography variant={'h2'}>“{quote}”</Typography>
        </Hidden>
      </Box>
      {image && (
        <Avatar
          sx={{
            height: 80,
            width: 80,
            marginBottom: theme.spacing(3),
            [theme.breakpoints.only('xs')]: {
              marginTop: theme.spacing(3)
            }
          }}
        >
          <Image alt={name} src={image} height={80} width={80} />
        </Avatar>
      )}
      <Box height={48} display={'flex'} flexDirection={'column'}>
        <Typography component={'p'} variant={'h6'}>
          {name}
        </Typography>
        {tagline && <Typography variant={'body1'}>{tagline}</Typography>}
      </Box>
    </Box>
  );
};

QuoteCarouselItem.strapiComponent = 'maastr.quote-carousel-item';

export { QuoteCarousel, QuoteCarouselItem };
